import { Modal } from 'bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '../../../../../../Foundation/Common/code/Views/Foundation/Common/Checkbox';
import Input from '../../../../../../Foundation/Common/code/Views/Foundation/Common/Input';
import { identity, signupSources, identitySources, getBase64EmailCustomField } from '/Foundation/CDP/code/Utilities/cdpHelper';
import { cdpSubscribe } from '/Foundation/Common/code/Helpers/newsletterHelper';
import Cookies from 'js-cookie';

const PopupMainContainer = (props) => {
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [email, setEmail] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [emailHasErrors, setEmailHasErrors] = useState(false);
  const modal = useRef();
  const popup = useRef();

  useEffect(() => {
    const hidePopup = Cookies.get(`NewsletterSignupShown_${props.Version}`);
    if (hidePopup) return;

    setTimeout(() => {
      popup.current = new Modal(modal.current);
      popup.current.show();

      //Cookie for not showing modal again after seeing it, depending on Sitecore setting - or default = 7 days
      let cookieExpire = new Date();
      const expiryDays =
        props.CookieExpirationDays && !isNaN(parseInt(props.CookieExpirationDays))
          ? parseInt(props.CookieExpirationDays)
          : 7;
      cookieExpire.setDate(cookieExpire.getDate() + expiryDays);
      Cookies.set(`NewsletterSignupShown_${props.Version}`, true, {
        expires: cookieExpire,
      });
    }, [5000]);
  }, []);

  const handleSubscribe = () => {
    if (!email || !privacyPolicyAccepted || (props.EnableZipCodeTextbox && !zipCode)) return;

    if (emailIsInvalid()) {
      setEmailHasErrors(true);
      return;
    }

    identity({
      email: email,
      postalCode: zipCode,
      checkTrackingEnabled: false,
      source: identitySources.popupNewsletterSignup,
    });

    const customFieldsFromForm = [];
    if (props.EnableZipCodeTextbox) {
      customFieldsFromForm.push({
        name: 'ZipCode',
        value: zipCode,
      });
    }

    customFieldsFromForm.push(getBase64EmailCustomField(email));
    cdpSubscribe(signupSources.popup, customFieldsFromForm);

    popup.current.hide();

    //Cookie for not showing modal again after signing up will expire in a year
    let cookieExpire = new Date();
    cookieExpire.setFullYear(cookieExpire.getFullYear() + 1);
    Cookies.set(`NewsletterSignupShown_${props.Version}`, true, {
      expires: cookieExpire,
    });
  };

  const emailIsInvalid = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/.test(email)) {
      return false;
    }
    return true;
  };

  return (
    <div className="popup modal fade" ref={modal}>
      <div className="modal-dialog">
        <div className="modal-content pt-4 pb-8 pb-lg-10">
          <div className="close-btn-container px-4 mb-lg-4">
            <button
              type="button"
              className="btn close-btn p-0 rpa-popup-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="le-icon-x"></i>
            </button>
          </div>
          {props.Title && (
            <>
              <div className="modal-header px-6 px-lg-10 py-0">
                <h3 className="heading3 w-100 mb-3">{props.Title}</h3>
              </div>
            </>
          )}
          <div className="modal-body px-6 px-lg-10 py-0">
            <div className="intro mb-8" dangerouslySetInnerHTML={{ __html: props.Intro }}></div>

            {props.EnableNewsletterSignupForm && (
              <>
                {props.EnableZipCodeTextbox && (
                  <>
                    <div className="mb-8">
                      <Input
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        value={zipCode}
                        label={`${props.ZipCodeLabel} *`}
                        onChange={(e) => {
                          setZipCode(e.currentTarget.value);
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="mb-8">
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    label={`${props.EmailLabel} *`}
                    onChange={(e) => {
                      setEmailHasErrors(false);
                      setEmail(e.currentTarget.value);
                    }}
                    errorMessage={props.EmailErrorMessage}
                    hasError={emailHasErrors}
                  />
                </div>
                <Checkbox
                  id="acceptCheckbox"
                  type="checkbox"
                  name="acceptCheckbox"
                  label={props.AcceptCheckboxText}
                  checked={privacyPolicyAccepted}
                  onChange={() => setPrivacyPolicyAccepted(!privacyPolicyAccepted)}
                />
                <button
                  onClick={() => handleSubscribe()}
                  className="btn thule-btn-default w-100 mt-5"
                  disabled={
                    !email || !privacyPolicyAccepted || emailHasErrors || (props.EnableZipCodeTextbox && !zipCode)
                  }
                >
                  {props.SignupButtonText}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMainContainer;
